import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"


import ProjectHeader from "../components/project-header"
import ProjectBlurb from "../components/project-blurb"

const info = {
    duration: "2019 onward",
    references: [
        {
            head: "Engineering Reference",
            name: "Nelson Davis, P.E.",
            employer: "KPG",
        },
        {
            head: "Client Reference",
            name: "Len Madsen",
            title: "Transportation Project Manager",
            employer: "City of Des Moines",
            phone: "(206) 783 7373",
        },
    ],
}

const Page = () => {
    const data = useStaticQuery(graphql`
      query {
        header: file(relativePath: { eq: "project-header--des-moines.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mapp: file(relativePath: { eq: "map--des-moines.png" }) {
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extra: file(relativePath: { eq: "extra--des-moines.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1040) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    console.log(data)
    return (
        <Layout hideMenu={true}>
          <SEO title="Des Moines Gateway Project" />
          <ProjectHeader
            client="City of Des Moines"
            project="Transportation Gateway Project"
            image={data?.header?.childImageSharp?.fluid}
          />
          <ProjectBlurb data={info} mapImage={data.mapp.childImageSharp.fluid}>
            <p>
              The Transportation Gateway Project is a multi-phase project
              located within the City of Des Moines and abuts the City of
              SeaTac.  Two City arterial street were upgraded to serve
              transportation network needs for planned commercial and
              residential development: South 216th Street between I5 and
              19th Avenue South, and 24th Avenue South 216th Street.
              Responsible for successful completion of PFEs and 28 partial
              fee acquisitions from residential, multi-family, and
              commercial properties.  RES Group took phase 1 and 2 through
              certification with WSDOT and is currently working on final
              phase 3 of the project.  Additionally, RES Group provided
              support and was an expert witness for the City on 1 property
              condemnation proceeding.
            </p>
          </ProjectBlurb>
          <Img
            className="extraMedia"
            alt="Project description"
            fluid={data.extra.childImageSharp.fluid} />
        </Layout>
    )
}

export default Page
