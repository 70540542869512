import React from "react"
import Img from "gatsby-image"

import style from "./styles/project-blurb.module.scss"

const ProjectBlurb = ({ children, data, mapImage }) => (
    <>
      <section className={style.component}>
        {data ?
         <aside>
           {data.duration ?
            <>
              <h2>Duration</h2>
              <p>{data.duration}</p>
            </>
            : false}
           {data.references ?
            data.references.map(ref => (
                <>
                  <h2>{ref.head}</h2>
                  <p>
                    {ref.name ? <>{ref.name}<br /></> : false}
                    {ref.title ? <>{ref.title}<br /></> : false}
                    {ref.employer ? <>{ref.employer}< br/></> : false}
                  </p>
                </>
            ))
            : false}
           {data.linksOut ?
            data.linksOut.map( link => (
                <a className={style.linkOut} target="_blank" rel="noreferrer" href={link.href}>{link.text}</a>
            ))
            : false}
         </aside>
         : false}
        <main className={style.main}>
          {children}
          <Img className={style.mapp} alt="Map" fluid={mapImage} />
        </main>
      </section>
    </>
)

export default ProjectBlurb
