import React from "react"
import Img from "gatsby-image"

import style from "./styles/project-header.module.scss"

const ProjectHeader = ({ client, project, image }) => (
    <section className={style.component}>
      <h1 className={style.project}>{project}</h1>
      <p className={style.client}>{client}</p>
      <Img className={style.image} fluid={image} alt={`${project} header image`}/>
    </section>
)

export default ProjectHeader
